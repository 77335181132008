import React from 'react'
import './Messages.css'
function Messages() {
    return (
        <div>
            
        </div>
    )
}

export default Messages;

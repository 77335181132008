import React from 'react'
import"./Chatbox.css"
import moment from 'moment';

export const Sender = ({data,date,contact,direction}) => {
    var time = moment(date).format('hh:mm A');
    // console.log("time : ",time)
    return (
        <div className="sender-textbox">
            <div className="text-chat-time">
            {time}

            </div>
            <div className="text-chat-inside">

            {data}
            </div>
            
        </div>
    )
}

import { Colors } from "../../utils/Colors";

export const themeStyles = (width, height, menuOnTop,backgroundColor) => {
	const normalStyles = {
		control: current => ({
			...current,
			width,
			height,
			borderColor: '#fff',
			backgroundColor: backgroundColor,
			cursor: "pointer",
			fontWeight: "500",
			boxShadow: "none",
			minHeight: "initial",
			
			// paddingLeft:"10px"
		}),
		dropdownIndicator: (current, { selectProps: { menuIsOpen } }) => ({
			...current,
			color: Colors.blackShade3,
			transition: "0.5s",
			...(menuIsOpen && {
				transform: "rotate(180deg)",
			}),
		}),
		indicatorSeparator: current => ({
			...current,
			display: "none",
		}),
		menu: current => ({
			...current,
			width,
			zIndex: "2",
			...(menuOnTop && { bottom: "40px", top: "unset" }),
		}),
		menuList: current => ({
			...current,
			padding: 0,
			maxHeight: "135px",
		}),
		option: (current, { isSelected, isFocused }) => ({
			...current,
			...(isSelected && { backgroundColor: Colors.blueShade1 }),
			...(isFocused && {
				backgroundColor: isSelected ? Colors.blueShade1 : Colors.whiteShade1,
				color: isSelected ? Colors.white : Colors.black,
			}),
			cursor: "pointer",
		}),
	};

	const transparentStyles = {
		control: current => ({
			...current,
			width,
			border: "none",
			backgroundColor: "none",
			cursor: "pointer",
			fontWeight: "500",
			boxShadow: "none",
		}),
		dropdownIndicator: (current, { selectProps: { menuIsOpen } }) => ({
			...current,
			color: Colors.blueShade1,
			transition: "0.5s",
			...(menuIsOpen && {
				transform: "rotate(180deg)",
			}),
		}),
		indicatorSeparator: current => ({
			...current,
			display: "none",
		}),
		menu: current => ({
			...current,
			cursor: "pointer",
			top: "30px",
			zIndex: "2",
			...(menuOnTop && { bottom: "30px", top: "unset" }),
		}),
		menuList: current => ({
			...current,
			padding: 0,
			maxHeight: "135px",
		}),
		option: (current, { isSelected, isFocused }) => ({
			...current,
			...(isSelected && { backgroundColor: Colors.blueShade1 }),
			...(isFocused && {
				backgroundColor: isSelected ? Colors.blueShade1 : Colors.whiteShade1,
				color: isSelected ? Colors.white : Colors.black,
			}),
			cursor: "pointer",
		}),
		singleValue: current => ({
			...current,
			right: 0,
			textOverflow: "visible",
		}),
	};

	return [normalStyles, transparentStyles];
};

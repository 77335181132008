// import logo from './logo.svg';
import "./App.css";
import React from "react";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { AuthProvider } from "./Context/AuthContext";
import { CallIframeProvider } from "./Context/CallIframeContext";
import "./Styles/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginScreen from "./Pages/LoginScreen/LoginScreen";
import Contact from "./Pages/Contact/Contact";
import CallHistory from "./Pages/CallHistory/CallHistory";
import ContactDetails from "./Pages/ContactDetails/ContactDetails";
import Messages from "./Pages/Messages/Messages";
import Signout from "./Pages/Signout/Signout";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CallIframeProvider>
          <Routes>
            {/* Open Routes */}
            <Route path="/login" exact element={<LoginScreen />} />
            {/* Protected Routes using Outlet and Navigate */}
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" exact element={<CallHistory />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/callhistory" exact element={<CallHistory />} />
              <Route
                path="/contact/Contact_:id"
                exact
                element={<ContactDetails />}
              />
              <Route path="/messages" exact element={<Messages />} />
              <Route path="/signout" exact element={<Signout />}></Route>
            </Route>
          </Routes>
        </CallIframeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

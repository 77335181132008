import React, { createContext, useState, useEffect } from "react";
import ls from "local-storage";
import config from "../utils/config";
import axios from "axios";
import { FetchConfig } from "../utils/config";

export const AuthContext = createContext();

const url = config.rootUrl;

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (authenticated) {
      FetchConfig();
    }
  }, [authenticated]);

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  const checkIfLoggedIn = () => {
    setLoading(true);
    const apiKey = ls.get("apiKey");
    const userId = ls.get("userId");
    // console.log("userId", userId);
    var data = [];
    if (apiKey && userId) {
      setAuthenticated(true);
      data = ls.get("userData");
      setCurrentUser(data);
      setLoading(false);
      return true;
    } else {
      //do nothing
      setAuthenticated(false);
      setLoading(false);
      return false;
    }
  };

  const getNumbers = async userDetails => {
    var numbers = [];
    var smsNumbers = [];
    if (userDetails && userDetails.numbers) {
      userDetails.numbers.every(function (number) {
        var num = number.number;
        var obj = {
          format: number.format,
          number: num.toString(),
          canSendSMS: number.is_sms,
        };
        if (number.is_sms === true) {
          smsNumbers.push(obj);
        }
        numbers.push(obj);
        return true;
      });
      return { numbers, smsNumbers };
    }
  };

  const fetchUserDetails = async (apiKey, userId) => {
    try {
      if (apiKey && userId) {
        var response = await axios.get(url, {
          headers: {
            apikey: apiKey,
          },
        });

        response = await axios.get(`${url}/contacts/getUserDetails/${userId}`, {
          headers: {
            apikey: apiKey,
          },
        });
        var user = response.data;
        return user;
      } else {
        return false;
      }
    } catch (err) {
      // console.log("err", err);
    }
  };

  const signIn = async (apiKey, navigate, pathname) => {
    //var apiUrl = `${url}/contacts/auth`;
    try {
      setCurrentUser("");
      setAuthenticated(true);
      setError("");
      setLoading(false);
      // navigate(pathname);
      navigate("/callhistory");
    } catch (err) {
      // console.log("err", err);
      setLoading(false);
      setError(err.toString());
    } finally {
      setLoading(false);
    }
  };
  // const signIn = async (apiKey, navigate, pathname) => {
  //   //var apiUrl = `${url}/contacts/auth`;
  //   try {
  //     setLoading(true);
  //     if (apiKey) {
  //       var response = await axios.get(apiUrl, {
  //         headers: {
  //           apikey: apiKey,
  //         },
  //       });

  //       var userId = response.data.user_id;
  //       console.log("userid====================>", apiUrl);

  //       if (!userId) {
  //         console.log("user ID does not exist");
  //         setError("User ID does not exist");
  //       } else {
  //         ls.set("apiKey", apiKey);

  //         var userDetails = await fetchUserDetails(apiKey, userId);
  //         console.log("User details ==> " + userDetails);
  //         var { numbers, smsNumbers } = await getNumbers(userDetails);

  //         var data = {
  //           userId: userId,
  //           apiKey: apiKey,
  //           numbers: numbers,
  //           smsNumbers: smsNumbers,
  //         };

  //         ls.set("userId", userId);
  //         ls.set("userData", data);

  //         if (numbers.length !== 0) {
  //           // console.log(typeof numbers[0].number, numbers[0].number);
  //           ls.set("primaryPhone", JSON.parse(numbers[0].number));
  //         }

  //         if (smsNumbers.length !== 0) {
  //           // console.log(typeof smsNumbers[0].number, smsNumbers[0].number);
  //           ls.set("smsPhone", JSON.parse(smsNumbers[0].number));
  //         }

  //         setCurrentUser(data);
  //         setAuthenticated(true);
  //         setError("");
  //         setLoading(false);
  //         // navigate(pathname);
  //         navigate("/callhistory");
  //       }
  //     } else {
  //       console.log("api key not found");
  //     }
  //   } catch (err) {
  //     console.log("err", err);
  //     setLoading(false);
  //     setError(err.toString());
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const signOut = async () => {
    //SIGN OUT
    setAuthenticated(false);
    ls.remove("apiKey");
    ls.remove("userId");
    ls.remove("userData");
    ls.remove("primaryPhone");
    ls.remove("smsPhone");
    setCurrentUser(null);
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("id_token");
    window.localStorage.removeItem("code_from_auth");
    window.localStorage.removeItem("region");

    //remove apikey and user id from local storage.
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        authenticated,
        error,
        checkIfLoggedIn,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

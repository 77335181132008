import React from "react";
import "./ExtraTagsModal.scss";
import OutsideClickHandler from "react-outside-click-handler";

const ExtraTagsModal = ({ setShowMoreTags, tags }) => {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowMoreTags(false);
      }}
    >
      <div className="extra-tags-modal">
        {tags.map((tag) => (
          <span
            className="ch-tag"
            style={{
              border: `1px solid #${tag.color}`,
              borderRadius: "4px",
              color: `#${tag.color}`,
              fontSize: "10px",
            }}
          >
            {tag.name}
          </span>
        ))}
      </div>
    </OutsideClickHandler>
  );
};

export default ExtraTagsModal;

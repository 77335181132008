import Incoming from "../Assets/Icons/Incoming.svg";
import Outgoing from "../Assets/Icons/Outgoing.svg";
import Missed from "../Assets/Icons/Missed.svg";
// import { CallIframeContext } from "../Context/CallIframeContext";
// import { useContext } from "react";

export default function sec2time(timeInSeconds) {
  var pad = function (num, size) {
      return ("000" + num).slice(size * -1);
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60);
  if (hours === 0) {
    return pad(minutes, 2) + ":" + pad(seconds, 2);
  } else {
    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
  }
}

//Call Direction Icon

export function callIcon(callDirection, callDuration) {
  if (callDirection === "in" && callDuration !== null && callDuration !== "0") {
    return Incoming;
  } else if (callDirection === "out") {
    return Outgoing;
  } else {
    return Missed;
  }
}

//Call Direction Text

export function callType(callDirection, callDuration) {
  if (callDirection === "in" && callDuration !== null && callDuration !== "0") {
    return <h6> Incoming call,</h6>;
  } else if (callDirection === "out") {
    return <h6> Outgoing call, </h6>;
  } else {
    return <h6> Missed call,</h6>;
  }
}

export function truncate(str, n) {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
}

export const scrollToBottom = (element) => {
  if (element) {
    element.scrollTop = element.scrollHeight;
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import "./ContactDetails.css";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
// import { ReactComponent as Call } from "../../Assets/Icons/Call.svg";
import { ReactComponent as Send } from "../../Assets/Icons/Send.svg";
import { sendSms } from "../../utils/Api";
// import { dial } from "../../utils/Api";
// import Select from "react-select";
import { AuthContext } from "../../Context/AuthContext";
import Select from "../../components/Select/Select";
import "./Chat.css";
import { Receiveer } from "../../components/Chat/Receiveer";
import { Sender } from "../../components/Chat/Sender";
import axios from "axios";
import { CallHistoryContacts } from "../../components/CallHistoryContacts/CallHistoryContacts";
// import { config } from "react-transition-group";
import config from "../../utils/config";
import { ReactComponent as Briefcase } from "../../Assets/Icons/Briefcase.svg";
import { ReactComponent as Dot } from "../../Assets/Icons/Dot.svg";
import { ReactComponent as Arrow } from "../../Assets/Icons/Arrow.svg";
import { scrollToBottom } from "../../utils/Functions";
import { checkAvailability } from "../../utils/Api";
import { ReactComponent as Loader } from "../../Assets/Icons/Loader.svg";
import CallViaFrame from "../../components/CallViaFrame/CallViaFrame";
import { ReactComponent as MsgWarning } from "../../Assets/Images/warning-msg.svg";

function format_full_name(text) {
  let full_name = text.split(" ");
  let initials = full_name[0][0];
  if (text) {
    if (full_name.length >= 2 && full_name[1]) {
      initials += full_name[1][0];
    }
  } else {
    initials = '';
  }

  return initials.toString().toUpperCase();
}

const ContactDetails = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [selectedOptionTo, setSelectedOptionTo] = useState(null);
  const [selectedOptionFrom, setSelectedOptionFrom] = useState(
    JSON.parse(localStorage.getItem("smsPhone"))
  );
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [fetchingChat, setFetchingChat] = useState(false);
  const [sending, setSending] = useState(false);
  const [availabe, setAvailabe] = useState(false);
  const contact = location.state.contact;
  const searchedWord = location.state.searchedWord;
  const contacts = location.state.contacts;

  useEffect(() => {
    if (selectedOptionTo !== null) {
      getchat();
    }
  }, [selectedOptionTo]);

  const isAvailable = async () => {
    if (contact.isUser) {
      const isAvailable = await checkAvailability(contact.user_id);
      setAvailabe(isAvailable);
    }
  };

  const scrollChatToBottom = () => {
    const element = document.getElementsByClassName(
      "chatbox-message-history"
    )[0];
    scrollToBottom(element);
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [chats, loading]);

  async function getchat(value) {
    setFetchingChat(true);
    const uid = localStorage.getItem("userId");
    try {
      var to = selectedOptionTo === null ? value : selectedOptionTo;
      var url = `https://addon.ringover-extension.xyz/contacts/getChatFromPhoneNumber?userId=${uid}&phoneNumber=${to}&pageLimit=10`;
      var response = await axios.get(url, {
        headers: {
          apikey: config.apikey,
        },
      });
      setChats(response.data.message_list.reverse());
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingChat(false);
    }
  }

  async function getNumberSender() {
    var obj = [];
    if (contact.numbers && contact.numbers.length !== 0) {
      contact.numbers.map((num) => {
        return obj.push({ label: num.format.international, value: num.number });
      });
    }

    await setSelectedOptionTo(obj[0].value);
    await getCallHistoryByContact(obj[0].value);
    await getchat(obj[0].value);
    await isAvailable();
    setLoading(false);
  }

  const sendToOptions = contact.numbers.map((number) => ({
    label: number.format.international,
    value: number.number,
  }));

  const sendFromOptions = currentUser.smsNumbers.map((number) => ({
    label: number.format.international,
    value: JSON.parse(number.number),
  }));

  useEffect(() => {
    getNumberSender();
  }, []);

  useEffect(() => {
    localStorage.setItem("smsPhone", JSON.parse(selectedOptionFrom));
  }, [selectedOptionFrom]);

  const handleSendSMS = async () => {
    setSending(true);

    try {
      if (message !== "") {
        await sendSms(selectedOptionTo, message);
        await getchat();
        setSending(false);
        setMessage("");
      } else {
        alert("Please enter some message");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  async function getCallHistoryByContact(value) {
    const uid = localStorage.getItem("userId");
    try {
      var to = selectedOptionTo === null ? value : selectedOptionTo;
      var url =
        config.rootUrl +
        `/contacts/getCallHistoryByContact?userId=${uid}&contactNumber=${to}&pageLimit=10`;
      var response = await axios.get(url, {
        headers: {
          apikey: config.apikey,
        },
      });
      if (response.status === 200) {
        await setCallHistory(response.data.call_list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="card-field-header ">
        <div
          className="back"
          onClick={() => {
            navigate("/contact", {
              state: { searchedWord, contacts },
            });
          }}
        >
          <Arrow />
        </div>
        {contact.isUser ? (
          <div className="user-profile-image-box">
            {contact.picture && (
              <img
                alt=""
                className="user-profile-image"
                src={contact.picture}
              />
            )}
          </div>
        ) : contact.initial !== null || contact.initial !== "" ? (
          <div className="card-inital-header">
            {contact.initial || contact.concat_name.charAt(0)}
          </div>
        ) : null}
        <div className="card-font-header">{contact.concat_name}</div>
        {!loading && contact.isUser && (
          <div className="available">
            {availabe ? (
              <>
                <Dot style={{ color: "#00DDD0" }} /> Available
              </>
            ) : (
              <>
                {" "}
                <Dot color="#EF525E" /> Unavailable
              </>
            )}
          </div>
        )}
      </div>

      <div className="main-box-contact-details">
        {loading ? (
          <div className="details-loader">
            <Loader />
          </div>
        ) : (
          <>
            <div className="lft-box">
              <div className="contact-sub-box">
                <span className="contact-card-box-header">Contact Info</span>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.7rem",
                    maxHeight: "110px",
                    justifyContent: "flex-start",
                    overflow: "auto",
                    marginTop: "8px",
                  }}
                >
                  <div>
                    {contact.company && contact.company !== "" && (
                      <div style={{ display: "flex", marginTop: "2px" }}>
                        <div className="company-title">{contact.company}</div>
                        <div
                          style={{
                            display: "flex",

                            justifyContent: "right",
                          }}
                        >
                          <Briefcase className="briefcase-icon cursor-pointer" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="contact-card-box-phone-number">
                    {contact.numbers.map((call, ind) => {
                      var number = String(call.number);
                      if (number[0] !== "+") {
                        var to = "+" + number;
                      } else {
                        to = number;
                      }
                      return (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "2px",
                            justifyContent: "space-between",
                          }}
                        >
                          {call?.format?.international ?? to}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            <CallViaFrame
                              styles={{
                                marginRight: "25px",
                                transform: "scale(0.86)",
                              }}
                              to={to?.format?.international ?? to}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {callHistory && callHistory.length !== 0 ? (
                <div className="callhistory-contactdetails">
                  <span className="callhistory-contactdetails-header">
                    Call History
                  </span>

                  <div className="callhistory-contactdetails-sub">
                    {callHistory.map((key) => {
                      return (
                        <CallHistoryContacts
                          direction={key.direction}
                          startTime={key.start_time}
                          totalDuration={key.total_duration}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="right-box">
              <div className="chatbox-header">
                <div className="chatbox-initial">{contact.initial}

                {contact && contact.initial && contact.initial !== "" ? contact.initial
                  : contact && contact.lastname && !contact.initial ? format_full_name(contact.lastname)
                    : "NC"}
                </div>
                <div className="chatbox-header-name">
                  {selectedOptionTo !== null &&
                    (sendToOptions.length !== 1 ? (
                      <div className="chatboc-dropdown-number">
                        <span className="text-header-number">
                          <Select
                            options={sendToOptions}
                            value={selectedOptionTo}
                            name="value"
                            setValue={setSelectedOptionTo}
                            height="7vh"
                            width="170px"
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="send-to-option">
                        {sendToOptions[0].label}
                      </div>
                    ))}
                </div>

                <div className="smsFrom">
                  {sendFromOptions.length !== 1 ? (
                    <Select
                      options={sendFromOptions}
                      value={selectedOptionFrom}
                      name="value"
                      setValue={setSelectedOptionFrom}
                      height="7vh"
                      width="170px"
                    />
                  ) : (
                    <div className="smsFrom-1">{sendFromOptions[0].label}</div>
                  )}
                </div>
              </div>
              <div className="chatbox-message-history">
                <div
                  className={`history-container ${
                    contact?.isUser ? "userHistory" : ""
                  }`}
                >
                  {contact?.isUser ? (
                    <MsgWarning />
                  ) : fetchingChat ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "58vh",
                      }}
                    >
                      <Loader />
                    </div>
                  ) : chats && chats.length !== 0 ? (
                    chats.map((message, index) => {
                      if (message.direction === "OUT") {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              marginRight: "26px",
                              marginTop: "9px",
                              marginBottom: "9px",
                            }}
                          >
                            <Sender
                              data={message.buffer}
                              date={message.creation_date}
                              contact={message.contact}
                              direction={message.direction}
                            />
                          </div>
                        );
                      } else if (message.direction === "IN") {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              marginLeft: "26px",
                            }}
                          >
                            <Receiveer
                              data={message.buffer}
                              date={message.creation_date}
                              contact={message.contact}
                              direction={message.direction}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  ) : (
                    <div className="start-chatting">No chats</div>
                  )}
                </div>
              </div>
              <div className="chatbox-footer">
                <div className="chatbox-textarea">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSendSMS();
                    }}
                  >
                    <input
                      className="text-input sms-box-text-area"
                      value={message}
                      onChange={(value) => setMessage(value.target.value)}
                      placeholder={
                        contact.isUser
                          ? "Cannot send message"
                          : "Type a message"
                      }
                      disabled={sending || contact?.isUser}
                    />
                  </form>
                </div>
                <div
                  className={`chatbox-sendbutton-msg ${
                    contact?.isUser ? "disabled " : ""
                  }`}
                  onClick={!sending && !contact?.isUser && handleSendSMS}
                >
                  {sending ? "Sending" : "Send"}
                  <Send style={{ marginLeft: "5.45px" }} />
                </div>
              </div>
              {/* </div> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ContactDetails;

import React, { useContext, useState, useEffect } from "react";
import { ReactComponent as Ringover } from "../../Assets/Icons/Ringover.svg";
import "./RingoverCall.scss";
import { CallIframeContext } from "../../Context/CallIframeContext";
// import { ReactComponent as Cancel } from "../../Assets/Icons/Cancel.svg";
import Alert from "react-bootstrap/Alert";

const getUA = () => {
  let device = "Unknown";
  const ua = {
    "Generic Linux": /Linux/i,
    "Android": /Android/i,
    "BlackBerry": /BlackBerry/i,
    "Bluebird": /EF500/i,
    "Chrome OS": /CrOS/i,
    "Datalogic": /DL-AXIS/i,
    "Honeywell": /CT50/i,
    "iPad": /iPad/i,
    "iPhone": /iPhone/i,
    "iPod": /iPod/i,
    "macOS": /Macintosh/i,
    "Windows": /IEMobile|Windows/i,
    "Zebra": /TC70|TC55/i,
  }
  Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
}
const RingoverCall = () => {
  const simpleSDK = useContext(CallIframeContext);
  const [show, setShow] = useState(false);
  const [checkIos, setCehckIOS] = useState('');
  const [zoom, setZoom] = useState('');
  useEffect(() => {

    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      if(typeof localStorage.getItem('userId') === "undefined" ||  localStorage.getItem('userId') === null ||  localStorage.getItem('userId') === ""){
        if(simpleSDK){
          simpleSDK.destroy();
        }
      }

    });
    
    setZoom(window.devicePixelRatio);
    setCehckIOS(getUA());
    simpleSDK.whoami('teams-integration')
    simpleSDK.generate();
    // simpleSDK.hide();
    simpleSDK.on("ringingCall", (e) => {
      simpleSDK.show();
    });
    simpleSDK.on("answeredCall", (e) => {
      simpleSDK.show();
    });
    simpleSDK.on("hangupCall", (e) => {
      simpleSDK.show();
    });
    document.querySelector("iframe").onmessage = function (e) {
    };
  }, []);

  return (
    <div>
      {checkIos && typeof checkIos !== 'undefined' && (checkIos === 'iPhone' || checkIos === 'iPad') ? (
        <div className="ringover-call-iframe">
          <div>
            {show && show &&
              <Alert variant="warning" style={{ width: "21rem" }} onClose={() => setShow(false)} dismissible>
                <Alert.Heading>
                  This application requires the use of cookies and they do not appear to be enabled on our OS (iOS). Currently, we do not support the use of the Ringover Dialer on ios. You can use the Ringover Dialer directly on Safari by accepting the cookies authorisation in browser settings. If you do not wish to accept the cookies authorisation, other features like call history, contact, and chat will still be available.
                </Alert.Heading>
              </Alert>
            }
          </div>
          <button
            onClick={() => {
              setShow(!show)
              // simpleSDK.toggle();
            }}
            className="call-btn"
          >
            <Ringover />
          </button>
        </div>
      ) : (
        <div className={zoom && zoom >= 1.5 ? 'ringover-call-iframe-zoom' : 'ringover-call-iframe'}>
         <button style={{display:"none"}}
            onClick={() => {
              simpleSDK.reload();
            }}
          >
           Test
          </button>
          <button
            onClick={() => {
              simpleSDK.toggle();
            }}
            className="call-btn"
          >
            <Ringover />
          </button>
        </div>
      )}

    </div>
  );
};

export default RingoverCall;

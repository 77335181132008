import ls from "local-storage";

const config = {
  rootUrl: "https://addon.ringover-extension.xyz",
  developmentUrl: "https://addon.ringover-extension.xyz",

  // rootUrl: 'https://addon-dot-apt-cubist-307713.ew.r.appspot.com/',
  apikey: ls.get("apiKey"),
  userId: ls.get("userId"), // store to local storage later
};

export const FetchConfig = async () => {
  config.apikey = ls.get("apiKey");
  config.userId = ls.get("userId");
};

export default config;

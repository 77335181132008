import React from 'react'
import"./Chatbox.css" 
import moment from 'moment'
export const Receiveer = ({data,date,contact,direction}) => {
    // console.log("data recive : ",data)
    var time = moment(date).format('hh:mm A');
    // console.log("time : ",time)
    return (
        <div className="reveiver-textbox">
            <div className="text-chat-time">
            {time}

            </div>
            <div className="text-chat-inside">

            {data}
            
            </div>
            
        </div>
    )
}

// export default Receiveer

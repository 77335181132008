import React from "react";
import "./Input.css";

const Input = ({
  value,
  label,
  name,
  placeholder,
  onChange,
  type,
  disabled,
  required,
}) => {
  return (
    <div className="input-style">
      {label && <label className="label-style">{label}</label>}
      <input
        className={`input-box ${disabled ? "input-disabled" : ""}`}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

export default Input;

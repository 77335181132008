import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../utils/config";

import CallHistoryTab from "./components/CallHistoryTab/CallHistoryTab";
import { ReactComponent as Loader } from "../../Assets/Icons/Loader.svg";
import moment from "moment";
// import SectionList from 'react-virtualized-sectionlist';
import Input from "../Input/Input";

import "./CallHistory.css";
import { checkPropTypes, func } from "prop-types";
export default function CallHistory() {
  const [callHistory, setCallHistory] = useState(null);
  const [newFetch, setNewFetch] = useState(false);
  const [resetFilterLog, setResetFilterLog] = useState(false);
  const [logFilterButton, setLogFilterButton] = useState("inital");
  const [limitOffset, setLimitOffset] = useState(0);
  const [lastTitle, setLastTitle] = useState("");
  const [newListCall, setNewListCall] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [dataNoContent, setDataNoContent] = useState(true);
  const [navigLang, setNavigLang] = useState("fr");
  var offset = new Date().getTimezoneOffset();
  const [paramsRoute, setParamsRoute] = useState("dashboard");
  const [startDate, setStartDate] = useState(
    moment().subtract(15, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [currentDateFilter, setCurrentDateFilter] = useState(
    moment().format("YYYY-MM-DD")
  );
  //
  const getRegion = localStorage.getItem("region")
    ? localStorage.getItem("region")
    : "eu";
  const [fetchFilter, setFetchFilter] = useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [dataFilter, setDataFilter] = useState(
    JSON.stringify({
      filter: "ALL",
      start_date: startDate + "T00:00:00+01:00",
      end_date: endDate + "T23:59:00+02:00",
      call_type: [],
      ivrs: [],
      groups: [],
      users: [],
      scenarios: [],
      stars: [],
      transfer_type: "ANSWERED",
      tags: [],
      tag_and: false,
      limit_count: 50,
      handle_call: "ALL",
      handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
      limit_offset: 0,
    })
  );

  const [dataFilterFetch, setDataFilterFetch] = useState(
    JSON.stringify({
      filter: "ALL",
      start_date: startDate + "T00:00:00+01:00",
      end_date: endDate + "T23:59:00+02:00",
      call_type: [],
      ivrs: [],
      groups: [],
      users: [],
      scenarios: [],
      stars: [],
      transfer_type: "ANSWERED",
      tags: [],
      tag_and: false,
      limit_count: 50,
      handle_call: "ALL",
      handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
      limit_offset: 0,
    })
  );

  const style = {
    lineHeight: "15px",
    color: "black",
    textAlign: "center",
    margin: "10px 5px",
    padding: "0 10px",
    minWidth: "50px",
    height: "40px",
    transition: ".2s",
    fontWeight: 500,
    fontSize: "12px",
    border: "1px solid #ced4da",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(227, 234, 248)",
  };

  const styleDivSelect = {
    lineHeight: "15px",
    color: "black",
    textAlign: "center",
    margin: "10px 5px",
    padding: "0 10px",
    minWidth: "50px",
    height: "40px",
    transition: ".2s",
    fontWeight: 500,
    fontSize: "12px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    let access_token = window.localStorage.getItem("access_token");
    let refresh_token = window.localStorage.getItem("refresh_token");
    let id_token = window.localStorage.getItem("id_token");

    if (!access_token || !refresh_token || !id_token) {
      localStorage.clear();
      //window.location.reload();
      //window.location.href = "https://teams.ringover-extension.xyz/login";
    }
  }, []);

  useEffect(() => {
    setNavigLang(
      navigator.language || navigator.userLanguage
        ? navigator.language || navigator.userLanguage
        : "fr"
    );
  }, []);

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      // console.log(bottom)
      setLimitOffset(limitOffset + 50);
      let parseJsonFetch = JSON.parse(dataFilterFetch);
      setDataFilterFetch(
        JSON.stringify({
          filter: parseJsonFetch.filter,
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: parseJsonFetch.call_type,
          ivrs: parseJsonFetch.ivrs,
          groups: [],
          users: fetchFilter.users,
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: limitOffset + 50,
        })
      );
      setNewFetch(true);
    }
  };

  const handleResetFilterLog = _ => {
    setResetFilterLog(true);
    setLogFilterButton("inital");
  };

  function handleSelectChange(event) {
    //console.log(event.target.value);
    logFilter(event.target.value);
    setSelectedClient(event.target.value);
  }

  function handleStartDateChange(event) {
    //console.log(event.target.value);
    if (typeof event.target.value !== "undefined") {
      setStartDate(event.target.value);
      logFilter("changeDate");
    }
  }
  function handleEndDateChange(event) {
    //console.log(event.target.value);
    if (typeof event.target.value !== "undefined") {
      setEndDate(event.target.value);
      logFilter("changeDate");
    }
  }

  useEffect(() => {
    if (logFilterButton == "inital") {
      document.getElementById("logFilterDirect").disabled = false;
      document.getElementById("logFilterUser").disabled = false;
      document.getElementById("logFilterTeam").disabled = false;
      document.getElementById("logFilterIvr").disabled = false;
    }
  }, [logFilterButton]);
  const logFilter = settings => {
    //  setLogFilterButton('inital');

    if (settings == "changeDate") {
      setParamsRoute("APP");

      document.getElementById("logFilterDirect").disabled = true;
      document.getElementById("logFilterUser").disabled = true;
      document.getElementById("logFilterTeam").disabled = true;
      document.getElementById("logFilterIvr").disabled = true;
      // console.log(settings)
      setDataFilter(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: 0,
        })
      );
      setDataFilterFetch(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: limitOffset,
        })
      );
      setFetchFilter(true);
    }
    if (
      settings == "ANSWERED" ||
      settings == "MISSED" ||
      settings == "VOICEMAIL" ||
      settings == "OUT"
    ) {
      document.getElementById("logFilterDirect").disabled = true;
      document.getElementById("logFilterUser").disabled = true;
      document.getElementById("logFilterTeam").disabled = true;
      document.getElementById("logFilterIvr").disabled = true;
      // console.log(settings);
      setParamsRoute("APP");

      setDataFilter(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [settings],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: 0,
        })
      );
      setDataFilterFetch(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [settings],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: limitOffset,
        })
      );
      setFetchFilter(true);
    }
    if (settings == "ivrs") {
      document.getElementById("logFilterDirect").disabled = true;
      document.getElementById("logFilterUser").disabled = true;
      document.getElementById("logFilterTeam").disabled = true;
      document.getElementById("logFilterIvr").disabled = true;
      setLogFilterButton("logFilterIvr");
      setParamsRoute("APP");

      //console.log(settings);
      setDataFilter(
        JSON.stringify({
          filter: "IVR",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: 0,
        })
      );
      setDataFilterFetch(
        JSON.stringify({
          filter: "IVR",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: limitOffset,
        })
      );
      setFetchFilter(true);
    }
    if (settings == "team_log") {
      document.getElementById("logFilterDirect").disabled = true;
      document.getElementById("logFilterUser").disabled = true;
      document.getElementById("logFilterIvr").disabled = true;
      document.getElementById("logFilterTeam").disabled = true;
      // console.log(settings);
      setLogFilterButton("logFilterTeam");
      setParamsRoute("APP");

      setDataFilter(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: 0,
        })
      );

      setDataFilterFetch(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: limitOffset,
        })
      );
      setFetchFilter(true);
    }
    if (settings == "only_user_log") {
      document.getElementById("logFilterDirect").disabled = true;
      document.getElementById("logFilterUser").disabled = false;
      document.getElementById("logFilterIvr").disabled = true;
      document.getElementById("logFilterTeam").disabled = true;
      setParamsRoute("APP");

      setLogFilterButton("logFilterUser");

      // console.log(settings);
      setDataFilter(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [+localStorage.getItem("user_id")],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: 0,
        })
      );

      setDataFilterFetch(
        JSON.stringify({
          filter: "ALL",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [+localStorage.getItem("user_id")],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: limitOffset,
        })
      );

      setFetchFilter(true);
    }
    if (settings == "direc_line") {
      document.getElementById("logFilterDirect").disabled = false;
      document.getElementById("logFilterIvr").disabled = true;
      document.getElementById("logFilterUser").disabled = true;
      document.getElementById("logFilterTeam").disabled = true;
      setLogFilterButton("logFilterDirect");
      setParamsRoute("dashboard");
      // console.log(settings);
      setDataFilter(
        JSON.stringify({
          filter: "DIRECT",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: 0,
        })
      );

      setDataFilterFetch(
        JSON.stringify({
          filter: "DIRECT",
          start_date: startDate + "T00:00:00+01:00",
          end_date: endDate + "T23:59:00+02:00",
          call_type: [],
          ivrs: [],
          groups: [],
          users: [],
          scenarios: [],
          stars: [],
          transfer_type: "ANSWERED",
          tags: [],
          tag_and: false,
          limit_count: 50,
          handle_call: "ALL",
          handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
          limit_offset: limitOffset,
        })
      );
      setFetchFilter(true);
    }
  };

  useEffect(() => {
    if (resetFilterLog && resetFilterLog == true) {
      setDataNoContent(true);

      const fetchData = async () => {
        setLoading(true);
        try {
          fetch(
            "https://api-" +
              getRegion +
              ".ringover.com/v4/aud/" +
              paramsRoute +
              "/logs/calls",
            {
              method: "post",
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("id_token"),
              },
              body: JSON.stringify({
                filter: "ALL",
                start_date: startDate + "T00:00:00+01:00",
                end_date: endDate + "T23:59:00+02:00",
                call_type: [],
                ivrs: [],
                groups: [],
                users: [],
                scenarios: [],
                stars: [],
                transfer_type: "ANSWERED",
                tags: [],
                tag_and: false,
                limit_count: 50,
                handle_call: "ALL",
                handle_call_response_type: ["CALL", "SMS", "EMAIL", "AVOID"],
                limit_offset: 0,
              }),
            }
          )
            .then(response => {
              if (response.status == 204 || response.status == 403) {
                setLoading(false);
                setCallHistory([]);
              } else {
                response
                  .json()
                  .then(responseJs => {
                    if (
                      typeof responseJs !== "undefined" &&
                      typeof responseJs.cdr_list !== "undefined"
                    ) {
                      if (responseJs.cdr_list.length > 0) {
                        if (typeof responseJs.cdr_list !== "undefined") {
                          fetch(
                            "https://api-" +
                              getRegion +
                              ".ringover.com/v2/team/team_members",
                            {
                              method: "get",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization:
                                  "Bearer " + localStorage.getItem("id_token"),
                              },
                            }
                          )
                            .then(response => response.json())
                            .then(responseJson => {
                              fetch(
                                "https://api-" +
                                  getRegion +
                                  ".ringover.com/v2/team/standards",
                                {
                                  method: "get",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization:
                                      "Bearer " + localStorage.getItem("id_token"),
                                  },
                                }
                              )
                                .then(responseIvr => responseIvr.json())
                                .then(responseJsonIvr => {
                                  if (
                                    typeof responseJsonIvr !== "undefined" &&
                                    typeof responseJsonIvr.standards !== "undefined"
                                  ) {
                                    if (
                                      typeof responseJson !== "undefined" &&
                                      typeof responseJson.team_members !== "undefined"
                                    ) {
                                      if (responseJson.team_members.length > 0) {
                                        if (
                                          typeof responseJson.team_members !== "undefined"
                                        ) {
                                          //setTeamMember(responseJson.team_members);

                                          var datauserslog = name(
                                            responseJs.cdr_list,
                                            responseJson.team_members,
                                            responseJsonIvr.standards
                                          );
                                          formatData(datauserslog)
                                            .then(res => {
                                              setLastTitle(res[0].title);
                                              setCallHistory(res);

                                              setResetFilterLog(false);
                                            })
                                            .catch(
                                              error => console.log(error),
                                              setResetFilterLog(false),
                                              setLoading(false)
                                            );
                                        }
                                      }
                                    }
                                  }
                                })
                                .catch(error =>
                                  console.log(
                                    error,
                                    setLoading(false),
                                    setResetFilterLog(false)
                                  )
                                );
                            })
                            .catch(
                              error => console.log(error),
                              setLoading(false),
                              setResetFilterLog(false)
                            );
                          // setNewListCall(true);
                        }
                      }
                    }
                  })
                  .catch(error => console.log(error));
              }
            })
            .catch(error => console.log(error));
        } catch (err) {
          console.log("Failed to load data: ", err.message);
        }
      };

      fetchData().then(() => {
        setLoading(false);
      });
    }
  }, [resetFilterLog]);

  useEffect(() => {
    setDataNoContent(true);

    const fetchData = async () => {
      setLoading(true);
      try {
        fetch(
          "https://api-" +
            getRegion +
            ".ringover.com/v4/aud/" +
            paramsRoute +
            "/logs/calls",
          {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("id_token"),
            },
            body: dataFilter,
          }
        )
          .then(response => {
            if (response.status == 204 || response.status == 403) {
              setCallHistory([]);
              setLoading(false);
            } else {
              response
                .json()
                .then(responseJs => {
                  if (
                    typeof responseJs !== "undefined" &&
                    typeof responseJs.cdr_list !== "undefined"
                  ) {
                    if (responseJs.cdr_list.length > 0) {
                      if (typeof responseJs.cdr_list !== "undefined") {
                        fetch(
                          "https://api-" +
                            getRegion +
                            ".ringover.com/v2/team/team_members",
                          {
                            method: "get",
                            headers: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              Authorization: "Bearer " + localStorage.getItem("id_token"),
                            },
                          }
                        )
                          .then(response => response.json())
                          .then(responseJson => {
                            fetch(
                              "https://api-" +
                                getRegion +
                                ".ringover.com/v2/team/standards",
                              {
                                method: "get",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization:
                                    "Bearer " + localStorage.getItem("id_token"),
                                },
                              }
                            ).then(responseIvr => {
                              if (
                                responseIvr.status == 204 ||
                                responseIvr.status == 403
                              ) {
                                setLoading(false);
                              } else {
                                responseIvr
                                  .json()
                                  .then(responseJsonIvr => {
                                    if (
                                      typeof responseJsonIvr !== "undefined" &&
                                      typeof responseJsonIvr.standards !== "undefined"
                                    ) {
                                      if (
                                        typeof responseJson !== "undefined" &&
                                        typeof responseJson.team_members !== "undefined"
                                      ) {
                                        if (responseJson.team_members.length > 0) {
                                          if (
                                            typeof responseJson.team_members !==
                                            "undefined"
                                          ) {
                                            //setTeamMember(responseJson.team_members);

                                            var datauserslog = name(
                                              responseJs.cdr_list,
                                              responseJson.team_members,
                                              responseJsonIvr.standards
                                            );

                                            formatData(datauserslog)
                                              .then(res => {
                                                if (res && typeof res !== "undefined") {
                                                  setLastTitle(res[0].title);
                                                  setCallHistory(res);
                                                }
                                              })
                                              .catch(error => console.log(error));
                                          }
                                        }
                                      }
                                    }
                                  })
                                  .catch(error => console.log(error, setLoading(false)));
                              }
                            });
                          })
                          .catch(error => console.log(error), setLoading(false));
                        // setNewListCall(true);
                      }
                    }
                  }
                })
                .catch(error => console.log(error));
            }
          })
          .catch(error => console.log(error));
      } catch (err) {
        console.log("Failed to load data: ", err.message);
      }
    };

    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setDataNoContent(true);

    if (newFetch) {
      setLoading(true);
      const fetchData = async () => {
        try {
          let formData = new FormData();
          // formData.append('id_token', '');
          // formData.append('id_token', '');
          formData.append("filter", "ALL");
          fetch(
            "https://api-" +
              getRegion +
              ".ringover.com/v4/aud/" +
              paramsRoute +
              "/logs/calls",
            {
              method: "post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("id_token"),
              },
              body: dataFilterFetch,
            }
          )
            .then(response => {
              if (response.status == 204 || response.status == 403) {
                // setLoading(false);
                // setCallHistory([]);
              } else {
                response
                  .json()
                  .then(responseJs => {
                    if (
                      typeof responseJs !== "undefined" &&
                      typeof responseJs.cdr_list !== "undefined"
                    ) {
                      if (responseJs.cdr_list.length > 0) {
                        if (typeof responseJs.cdr_list !== "undefined") {
                          fetch(
                            "https://api-" +
                              getRegion +
                              ".ringover.com/v2/team/team_members",
                            {
                              method: "get",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization:
                                  "Bearer " + localStorage.getItem("id_token"),
                              },
                            }
                          )
                            .then(response => response.json())
                            .then(responseJson => {
                              fetch(
                                "https://api-" +
                                  getRegion +
                                  ".ringover.com/v2/team/standards",
                                {
                                  method: "get",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization:
                                      "Bearer " + localStorage.getItem("id_token"),
                                  },
                                }
                              )
                                .then(responseIvr => responseIvr.json())
                                .then(responseJsonIvr => {
                                  if (
                                    typeof responseJsonIvr !== "undefined" &&
                                    typeof responseJsonIvr.standards !== "undefined"
                                  ) {
                                    if (
                                      typeof responseJson !== "undefined" &&
                                      typeof responseJson.team_members !== "undefined"
                                    ) {
                                      if (responseJson.team_members.length > 0) {
                                        if (
                                          typeof responseJson.team_members !== "undefined"
                                        ) {
                                          //setTeamMember(responseJson.team_members);
                                          var datauserslog = name(
                                            responseJs.cdr_list,
                                            responseJson.team_members,
                                            responseJsonIvr.standards
                                          );
                                          formatData(datauserslog)
                                            .then(res => {
                                              setLastTitle(res[0].title);
                                              //setCallHistory(res);
                                              setCallHistory(callHistory => [
                                                ...callHistory,
                                                res[0],
                                              ]);
                                              setNewFetch(false);
                                            })
                                            .catch(
                                              error => console.log(error),
                                              setLoading(false)
                                            );
                                        }
                                      }
                                    }
                                  }
                                })
                                .catch(error => console.log(error), setLoading(false));
                            })
                            .catch(error => console.log(error), setLoading(false));
                        }
                        // setNewListCall(true);
                      }
                    }
                  })
                  .catch(error => console.log(error));
              }
            })
            .catch(error => console.log(error), setLoading(false));
        } catch (err) {
          console.log("Failed to load data: ", err.message);
        }
      };
      fetchData().then(() => {
        setLoading(false);
      });
    }
  }, [newFetch]);

  useEffect(() => {
    setDataNoContent(true);
    if (fetchFilter) {
      setCallHistory(null);
      setLoading(true);
      const fetchData = async () => {
        try {
          let formData = new FormData();
          // formData.append('id_token', '');
          // formData.append('id_token', '');
          formData.append("filter", "ALL");
          fetch(
            "https://api-" +
              getRegion +
              ".ringover.com/v4/aud/" +
              paramsRoute +
              "/logs/calls",
            {
              method: "post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("id_token"),
              },
              body: dataFilter,
            }
          )
            .then(response => {
              if (response.status == 204 || response.status == 403) {
                setLastTitle("");
                setCallHistory([]);
                setFetchFilter(false);
                if (logFilterButton == "logFilterIvr") {
                  document.getElementById("logFilterDirect").disabled = false;
                  document.getElementById("logFilterUser").disabled = false;
                  document.getElementById("logFilterTeam").disabled = false;
                  document.getElementById("logFilterIvr").disabled = true;
                } else if (logFilterButton == "logFilterDirect") {
                  document.getElementById("logFilterUser").disabled = false;
                  document.getElementById("logFilterTeam").disabled = false;
                  document.getElementById("logFilterIvr").disabled = false;
                  document.getElementById("logFilterDirect").disabled = true;
                } else if (logFilterButton == "logFilterUser") {
                  document.getElementById("logFilterDirect").disabled = false;
                  document.getElementById("logFilterTeam").disabled = false;
                  document.getElementById("logFilterIvr").disabled = false;
                  document.getElementById("logFilterUser").disabled = true;
                } else if (logFilterButton == "logFilterTeam") {
                  document.getElementById("logFilterDirect").disabled = false;
                  document.getElementById("logFilterUser").disabled = false;
                  document.getElementById("logFilterIvr").disabled = false;
                  document.getElementById("logFilterTeam").disabled = true;
                }

                //setDataNoContent(false);
              } else {
                response.json().then(responseJs => {
                  if (
                    typeof responseJs !== "undefined" &&
                    typeof responseJs.cdr_list !== "undefined"
                  ) {
                    if (responseJs.cdr_list.length > 0) {
                      if (typeof responseJs.cdr_list !== "undefined") {
                        fetch(
                          "https://api-" +
                            getRegion +
                            ".ringover.com/v2/team/team_members",
                          {
                            method: "get",
                            headers: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              Authorization: "Bearer " + localStorage.getItem("id_token"),
                            },
                          }
                        )
                          .then(response => response.json())
                          .then(responseJson => {
                            fetch(
                              "https://api-" +
                                getRegion +
                                ".ringover.com/v2/team/standards",
                              {
                                method: "get",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization:
                                    "Bearer " + localStorage.getItem("id_token"),
                                },
                              }
                            )
                              .then(responseIvr => responseIvr.json())
                              .then(responseJsonIvr => {
                                if (
                                  typeof responseJsonIvr !== "undefined" &&
                                  typeof responseJsonIvr.standards !== "undefined"
                                ) {
                                  if (
                                    typeof responseJson !== "undefined" &&
                                    typeof responseJson.team_members !== "undefined"
                                  ) {
                                    if (responseJson.team_members.length > 0) {
                                      if (
                                        typeof responseJson.team_members !== "undefined"
                                      ) {
                                        //setTeamMember(responseJson.team_members);
                                        var datauserslog = name(
                                          responseJs.cdr_list,
                                          responseJson.team_members,
                                          responseJsonIvr.standards
                                        );
                                        formatData(datauserslog)
                                          .then(res => {
                                            setLastTitle(res[0].title);
                                            //setCallHistory(callHistory => [...callHistory, res[0]]);
                                            setCallHistory(res);

                                            setFetchFilter(false);
                                            if (logFilterButton == "logFilterIvr") {
                                              document.getElementById(
                                                "logFilterDirect"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterUser"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterTeam"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterIvr"
                                              ).disabled = true;
                                            } else if (
                                              logFilterButton == "logFilterDirect"
                                            ) {
                                              document.getElementById(
                                                "logFilterUser"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterTeam"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterIvr"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterDirect"
                                              ).disabled = true;
                                            } else if (
                                              logFilterButton == "logFilterUser"
                                            ) {
                                              document.getElementById(
                                                "logFilterDirect"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterTeam"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterIvr"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterUser"
                                              ).disabled = true;
                                            } else if (
                                              logFilterButton == "logFilterTeam"
                                            ) {
                                              document.getElementById(
                                                "logFilterDirect"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterUser"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterIvr"
                                              ).disabled = false;
                                              document.getElementById(
                                                "logFilterTeam"
                                              ).disabled = true;
                                            }
                                          })
                                          .catch(error => console.log(error));
                                      }
                                    }
                                  }
                                }
                              })
                              .catch(error => console.log(error), setLoading(false));
                          })
                          .catch(error => console.log(error), setLoading(false));
                      }
                      // setNewListCall(true);
                    }
                  }
                });
              }
            })
            .catch(error => console.log(error));
        } catch (err) {
          console.log("Failed to load data: ", err.message);
        }
      };
      fetchData().then(() => {
        setLoading(false);
      });
    }
  }, [fetchFilter]);

  function name(params, data, ivr) {
    if (data && data.length > 0) {
      params.forEach(element => {
        data.find(e => {
          if (e.id == element.user_id) {
            element["users"] = e;
          }
          if (typeof element.subcdrs !== "undefined" && element.subcdrs) {
            if (e.id == element.subcdrs[0].user_id) {
              element.subcdrs[0]["users"] = e;
            }
          }
        });
      });
    } else {
      console.log("error format users");
    }
    if (ivr && ivr.length > 0) {
      params.forEach(element => {
        ivr.find(e => {
          if (e.id == element.ivr_id) {
            element["ivrs"] = e;
          }
        });
      });
    } else {
      console.log("error format ivr");
    }

    return params;
  }

  const formatData = data => {
    return new Promise((resolve, reject) => {
      try {
        const callsByDate = data.reduce((acc, call) => {
          var date = moment(call.call_start_time).format("DD MMMM YYYY");
          const foundIndex = acc.findIndex(element => element.title === date);
          if (foundIndex === -1) {
            return [
              ...acc,
              {
                title: date,
                data: [call],
              },
            ];
          }
          acc[foundIndex].data = [...acc[foundIndex].data, call];
          return acc;
        }, []);
        resolve(callsByDate);
      } catch (err) {
        reject(err);
      }
    });
  };

  const RenderSectionHeader = ({
    title,
    data,
    // key,
    // style,
    // isScrolling,
    // isVisible,
    // parent,
  }) => {
    var checkTitle = newListCall ? true : false;

    if (checkTitle) {
      var currentDate = moment().format("DD MMMM YYYY");
      var yesterdayDate = moment().subtract(1, "day").format("DD MMMM YYYY");
      var header = title;
      if (title === currentDate) {
        header = "Today";
      } else if (title === yesterdayDate) {
        header = "Yesterday";
      } else {
        header = title;
      }
      return (
        <div style={{ paddingTop: "10px" }}>
          <div className="call-section-header">{header}</div>
          <div className="section-datalist">
            {data &&
              data.map((call, index) => {
                return (
                  <div className="call-section-card" key={index}>
                    <CallHistoryTab obj={call} key={index} />
                  </div>
                );
              })}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ paddingTop: "10px" }}>
          <div className="section-datalist">
            {data &&
              data.map((call, index) => {
                return (
                  <div className="call-section-card" key={index}>
                    <CallHistoryTab obj={call} key={index} />
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        onScroll={handleScroll}
        style={{
          backgroundColor: "#FAFAFA",
          display: "flex",
          flexDirection: "column",
        }}
        className="call-histories"
      >
        <br></br>
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
          <div class="btn-group mr-2" role="group" aria-label="First group">
            <div>
              <label> Date :</label>
              <input
                onChange={handleStartDateChange}
                style={{
                  margin: "5px",
                  color: "black",
                  border: "none",
                  backgroundColor: "rgb(227, 234, 248)",
                }}
                type="date"
                id="dateStart"
                value={startDate}
              />
            </div>
            <div>
              <input
                onChange={handleEndDateChange}
                style={{
                  margin: "5px",
                  color: "black",
                  border: "none",
                  backgroundColor: "rgb(227, 234, 248)",
                }}
                type="date"
                id="dateEnd"
                value={endDate}
                max={currentDateFilter}
              />
            </div>
            <div
              onClick={() => handleResetFilterLog()}
              style={{ cursor: "pointer", margin: "5px" }}
            >
              <p
                style={{
                  color: "blue",
                  border: "none",
                  textDecoration: "underline",
                  backgroundColor: "#e3eaf8",
                }}
              >
                {navigLang.startsWith("fr")
                  ? "Supprimer les filtres"
                  : "Clear ALL filters"}
              </p>
            </div>
          </div>
        </div>
        <br></br>
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
          <div class="btn-group mr-2" role="group" aria-label="First group">
            <button
              id="logFilterDirect"
              onClick={() => logFilter("direc_line")}
              style={style}
              type="button"
              class="btn btn-secondary"
            >
              {navigLang.startsWith("fr") ? "Lignes Directes" : "Direct Line"}
            </button>
            <button
              id="logFilterIvr"
              onClick={() => logFilter("ivrs")}
              style={style}
              type="button"
              class="btn btn-secondary"
            >
              {navigLang.startsWith("fr") ? "Standards" : "IVR"}
            </button>
            <div style={styleDivSelect} class="btn-group" role="group">
              <select
                value={selectedClient}
                onChange={handleSelectChange}
                style={style}
                id="callType"
              >
                <option disabled value="">
                  {navigLang.startsWith("fr") ? "Type d'appel" : "Call Type"}
                </option>
                <option value="ANSWERED">
                  {" "}
                  {navigLang.startsWith("fr") ? "Appels décrochés" : "Answered calls"}
                </option>
                <option value="MISSED">
                  {navigLang.startsWith("fr") ? "Appels manqués" : "Missed Calls"}
                </option>
                <option value="VOICEMAIL">
                  {navigLang.startsWith("fr") ? "Messages répondeur" : "Voicemails"}
                </option>
                <option value="OUT">
                  {navigLang.startsWith("fr") ? "Appels sortants" : "Outgoing Calls"}
                </option>
              </select>
            </div>
          </div>
          <div class="btn-group mr-2" role="group" aria-label="Second group">
            <button
              id="logFilterUser"
              onClick={() => logFilter("only_user_log")}
              style={style}
              type="button"
              class="btn btn-secondary"
            >
              {navigLang.startsWith("fr") ? "Mes logs" : "Only my logs"}
            </button>
            <button
              id="logFilterTeam"
              onClick={() => logFilter("team_log")}
              style={style}
              type="button"
              class="btn btn-secondary"
            >
              {navigLang.startsWith("fr") ? "Logs Team" : "Team logs"}
            </button>
          </div>
        </div>

        <br></br>

        {isLoading === true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Loader />
          </div>
        ) : callHistory !== null ? (
          callHistory.length > 0 ? (
            callHistory.map((section, index) => {
              return (
                <div key={index} className="section-header">
                  <RenderSectionHeader title={section.title} data={section.data} />
                </div>
              );
            })
          ) : (
            <>
              <br />
              <br />
              <div>
                <p style={{ fontWeight: "600", textAlign: "center" }}>
                  {navigLang.startsWith("fr")
                    ? "Journal des appels vide. Vous devriez peut-être essayer de changer vos filtres ou vérifier vos permissions"
                    : "Call logs empty. Maybe you should try to change your filters or verify your permissions"}
                </p>
              </div>
            </>
          )
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <Loader />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../utils/config";
import { ReactComponent as Loader } from "../../Assets/Icons/Loader.svg";
import ContactTab from "./ContactTab";
import Input from "../Input/Input";
import { useNavigate } from "react-router-dom";
import "./Contact.css";
import ls from "local-storage";
// import { ReactComponent as Filter } from "../../Assets/Icons/Filter.svg";
import { search as searchHelper } from "../../utils/RequestCreator";
import { useLocation } from "react-router-dom";

export default function Contacts() {
  const navigate = useNavigate();
  const location = useLocation();
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [searching, setSearching] = useState(0);
  // const [research, setResearch] = useState(true);
  const searchedWord = location.state?.searchedWord;
  const searchedContacts = location.state?.contacts ?? [];
  const [totalContacts, setTotalContacts] = useState(0);
  const [scrollLoad, setScrollLoad] = useState(false);
  const [limitOffset, setLimitOffset] = useState(0);
  const [newFetch, setNewFetch] = useState(false);
  const getRegion = localStorage.getItem("region") ? localStorage.getItem("region") : "eu";

  const handleNavigation = (id, data) => {
    navigate(`/contact/Contact_${id}`, {
      state: {
        contact: data,
        searchedWord: search,
        contacts: contacts,
      },
    });
  };

  useEffect(() => {
    // console.log(searchedWord);
    if (searchedWord) {
      setSearch(searchedWord);
      setContacts(searchedContacts);
      //setResearch(false);
      // navigate("#", { state: null});
    } else {
      fetchContacts();
    }
    return () => {
      setContacts([]);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search.length > 0) {
        if (search !== searchedWord) {
          onSearch(search);
        } else {
          setContacts(searchedContacts);
        }
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [search]);

  const onSearch = async (string) => {
  
    if(string && string != "" && string.length > 0){
      
      setSearching((prevState) => prevState + 1);
      setSearch(string);
  
      try {
  
        fetch("https://api-"+getRegion+".ringover.com/v3/contacts?alphabetical_order=LastnameFirstnameCompanyASC&search="+string+"&limit_count=30&limit_offset=0&pagination=a.",  {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('id_token')
          },
         
        }).then((response) => { response.json().then((responseJs) => {
          if(responseJs.contact_list && typeof responseJs.contact_list !== 'undefined'){
            setContacts(responseJs.contact_list ? responseJs.contact_list : []);
          }
        });
        }).catch((error) => console.log(error));
        
      } catch (error) {
        
      }finally {
        setSearching((prevState) => prevState - 1);
      }
    }else{
      setSearching(0);
      loadContacts();
    }
  };

  const fetchContacts = async (scrollLoading) => {

    try {

      fetch("https://api-"+getRegion+".ringover.com/v3/contacts?alphabetical_order=LastnameFirstnameCompanyASC&pagination=a&limit_count=30&limit_offset=0",  {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('id_token')
        },
       
      }).then((response) => {
        if (response.status == 204) {
          console.log('no contact')
        } else {
          response.json().then((responseJs) => {
            if(responseJs.contact_list && typeof responseJs.contact_list !== 'undefined'){
              setTotalContacts(responseJs.total_contact_count);
              setContacts((prevState) => [
                  ...prevState,
                  ...responseJs.contact_list,
                ]);
            }
            setOffset((prevState) => prevState + 100);
            if (scrollLoading) {
              document
                .querySelector(".contacts-page")
                .getBoundingClientRect().bottom += 20;
            }
          });
        }


      }).catch((error) => console.log(error));

    }
    catch (err) {
      console.log("Failed to load data: ", err.message);
    }
  };

   
  useEffect(() => {
    if (newFetch) {
    const NewfetchContacts = async () => {
        try {
    
          fetch("https://api-"+getRegion+".ringover.com/v3/contacts?alphabetical_order=LastnameFirstnameCompanyASC&pagination=a&limit_count=30&limit_offset="+limitOffset,  {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            },
           
          }).then((response) => {
            if (response.status == 204) {
              console.log('no contact')
              setNewFetch(false);
            } else {
              response.json().then((responseJs) => {
                if(responseJs.contact_list && typeof responseJs.contact_list !== 'undefined'){
                  setTotalContacts(responseJs.total_contact_count);
                  setContacts((prevState) => [
                      ...prevState,
                      ...responseJs.contact_list,
                    ]);
                }
                setOffset((prevState) => prevState + 100);
                // if (scrollLoading) {
                //   document
                //     .querySelector(".contacts-page")
                //     .getBoundingClientRect().bottom += 20;
                // }
                setNewFetch(false);
              });
            }
    
    
          }).catch((error) => console.log(error));
    
        }
        catch (err) {
          console.log("Failed to load data: ", err.message);
        }
      };
      NewfetchContacts();
    }
  }, [newFetch])

  const hasMoreContacts = () => {
    return offset <= totalContacts ? true : false;
  };

  const loadContacts = async () => {
    if (searching === 0 && !scrollLoad) {
      setTimeout(async () => {
        try {
          // if having more quotes to fetch
          if (hasMoreContacts()) {
            // call the API to get quotes
            await fetchContacts(true);
            // show quotes
          }
        } catch (error) {
          console.log(error.message);
        }
      }, 200);
    }
  };

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setLimitOffset(limitOffset + 30);
      setNewFetch(true);
    }
  };
  const onScrollx = () => {
    if (search.trim().length === 0) {
      let bottomOfContainerFromWindowTop = document
        .querySelector(".contacts-page")
        .getBoundingClientRect().bottom;
      let parentHeight = document
        .querySelector(".contact-main-card")
        .getBoundingClientRect().height;
      let heightRemainingFromBottomToBottomOfContainer =
        bottomOfContainerFromWindowTop - parentHeight;
      let percentageRemainingFromBottomToBottomOfContainer =
        (heightRemainingFromBottomToBottomOfContainer / parentHeight) * 100;
      // console.log(percentageRemainingFromBottomToBottomOfContainer);
      if (percentageRemainingFromBottomToBottomOfContainer < 50) {
        loadContacts();
      }
    }
  };

  return (
    <div
      className="contacts-section"
      style={{ backgroundColor: "#FAFAFA", flex: 1 }}
    >
      {/* <h1>Team Contacts</h1> */}
      <div className="search-box">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch(search);
          }}
        >
          <Input
            value={search}
            variant={"search"}
            onChange={(e) =>
              setSearch(
                e.target.value.trim().length === 0
                  ? e.target.value.trim()
                  : e.target.value
              )
            }
            placeholder={"Search"}
          />
        </form>
      </div>
      <div className="contact-main-card" onScroll={onScroll}>
        <div className="contacts-page">
          {contacts && searching === 0 ? (
            contacts.length > 0 ? (
              contacts.map((call, ind) => {
                // var contactId = call.contact_id;
                return (
                  <div>
                    <ContactTab
                      obj={call}
                      key={ind}
                      handleNavigation={handleNavigation}
                    />
                  </div>
                );
              })
            ) : (
              <div className="results-not-found">No results found</div>
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
        {scrollLoad ? <Loader /> : null}
      </div>
    </div>
  );
}

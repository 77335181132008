import React, { useState, useContext, useEffect } from "react";
import "./LoginScreen.css";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import logo from "../../Assets/Images/logo2.svg";
import { AuthContext } from "../../Context/AuthContext";
// import Dialer from '../../components/Dialer/Dialer'
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const endpoints = "https://auth.ringover.com/oauth2/authorize";
const access_token_url = "https://auth.ringover.com/oauth2/access_token";
const introspect_url = "https://auth.ringover.com/oauth2/introspect";
const logout_url = "https://auth.ringover.com/oauth2/logout";
let tokenTimeout = null;
var apiV2Url = "";
let accessTokenPromise = Promise.resolve();
let user_region = "";

const LoginScreen = () => {
  const navigate = useNavigate();
  const locations = useLocation();
  const pathname = locations.state?.pathname ?? "/callhistory";
  const { signIn, error, loading } = useContext(AuthContext);

  async function get_tokens() {
    try {
      return {
        access_token: localStorage.getItem("access_token"),
        refresh_token: localStorage.getItem("refresh_token"),
        id_token: localStorage.getItem("id_token"),
      };
    } catch (error) {
      console.log("Couldn't load data", error);
      return false;
    }
  }

  function save_tokens(res) {
    let formData = new FormData();
    formData.append("token", res.id_token);

    fetch(introspect_url, {
      method: "post",
      body: formData,
    })
      .then(response => response.json())
      .then(responseJson => {
        user_region = responseJson.region.toLowerCase();
        //introspect
        apiV2Url = "https://api-" + user_region + ".ringover.com/v2/get/user";
        window.localStorage.setItem("access_token", res.access_token);
        window.localStorage.setItem("refresh_token", res.refresh_token);
        window.localStorage.setItem("id_token", res.id_token);
        // tokenTimeout = setTimeout(this.refresh_oauth_tokens(), (res.expires_in * 1000) - 5000);

        window.localStorage.setItem("region", user_region);
        window.localStorage.setItem("user_id", responseJson.user_id);
        //
        signIn(signIn, navigate, pathname);
      })
      .catch(error => console.log(error));
  }

  function get_oauth2_tokens(code) {
    const verifier_code = window.localStorage.getItem("PCKE");

    const params = {
      grant_type: "authorization_code",
      redirect_uri: window.location.origin + "/login",
      code: code,
      code_verifier: verifier_code,
    };

    return call_oauth2_API(params)
      .then(res => {
        // console.log(res);
        if (res.error) {
          return oauth_auth();
        } else {
          try {
            localStorage.removeItem("PCKE");
            save_tokens(res);
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch(error => console.log(error));
  }

  function oauth_auth() {
    const generate_code_challenge = () => {
      const sequence_verifier = new Uint8Array(32);
      for (let i = 0; i < 32; i++) {
        sequence_verifier[i] = Math.floor(Math.random() * 255);
      }
      let binary_verifier = "";
      for (let unit8 of sequence_verifier) {
        binary_verifier += String.fromCharCode(unit8);
      }
      const code_verifier = btoa(binary_verifier)
        .replace(/=/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
      window.localStorage.setItem("PCKE", code_verifier);
      return window.crypto.subtle
        .digest("SHA-256", new TextEncoder().encode(code_verifier))
        .then(buffer => {
          const sequence_challenge = new Uint8Array(buffer);
          let binary_challenge = "";
          for (let unit8 of sequence_challenge) {
            binary_challenge += String.fromCharCode(unit8);
          }
          let code_challenge = btoa(binary_challenge)
            .replace(/=/g, "")
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
          return code_challenge;
        });
    };

    return generate_code_challenge()
      .then(code_challenge => {
        const params = {
          client_id: "teams_app",
          scope: "API_V2_ALL",
          response_type: "code",
          redirect_uri: window.location.origin + "/login",
          code_challenge_method: "S256",
          code_challenge: code_challenge,
        };

        let url = endpoints + "?";

        for (let param in params) {
          url += param + "=" + params[param] + "&";
        }

        window.location.href = encodeURI(url);
      })
      .catch(e => console.error(e));
  }

  function refresh_oauth_tokens(tokens) {
    return accessTokenPromise.then(() => {
      let params = {
        grant_type: "refresh_token",
        redirect_uri: window.location.origin + "/login",
        access_token: tokens.access_token,
        refresh_token: tokens.refresh_token,
        id_token: tokens.id_token,
      };

      let logout_oauth2 = _ => {
        remove_token();
        return oauth_auth();
      };

      accessTokenPromise = call_oauth2_API(params)
        .then(res => {
          if (res.error) {
            logout_oauth2();
          } else {
            save_tokens(res);
            return res.access_token;
          }
        })
        .catch(() => {
          logout_oauth2();
        });
      return accessTokenPromise;
    });
  }

  function call_oauth2_API(params) {
    let defaultParams = {
      client_id: "teams_app",
      scope: "API_V2_ALL",
    };

    params = Object.assign(defaultParams, params);

    let formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }
    return fetch(access_token_url, {
      method: "post",
      body: formData,
    }).then(res => {
      if (res.ok == true) {
        return res.json();
      } else {
        return Promise.reject();
      }
    });
  }

  function remove_token() {
    if (tokenTimeout) clearTimeout(tokenTimeout);
    window.localStorage.removeItem("user_token");
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("id_token");
    window.localStorage.removeItem("region");
  }

  function call_oauth2_API(params) {
    let defaultParams = {
      client_id: "teams_app",
      scope: "API_V2_ALL",
    };
    params = Object.assign(defaultParams, params);
    let formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }
    return fetch(access_token_url, {
      method: "post",
      body: formData,
    }).then(res => res.json());
  }

  useEffect(() => {
    get_tokens()
      .then(auth_token => {
        console.log(auth_token);

        if (
          !auth_token.id_token &&
          !auth_token.refresh_token &&
          !localStorage.getItem("code_auth") &&
          localStorage.getItem("PCKE")
        ) {
          oauth_auth()
            .then(() => {
              console.log("ok oauth_auth1");
            })
            .catch(error => console.log(error));
        }

        if (
          !auth_token.id_token &&
          !auth_token.refresh_token &&
          !localStorage.getItem("PCKE") &&
          !localStorage.getItem("code_auth")
        ) {
          oauth_auth()
            .then(() => {
              console.log("ok oauth_auth");
            })
            .catch(error => console.log(error));
        }
        if (
          !auth_token.id_token &&
          !auth_token.refresh_token &&
          localStorage.getItem("refresh") == 2 &&
          localStorage.getItem("code_auth")
        ) {
          get_oauth2_tokens(localStorage.getItem("code_auth"))
            .then(res => {
              console.log("get_oauth2_tokens is ok!");
              localStorage.removeItem("code_auth");
              localStorage.removeItem("PCKE");
              localStorage.removeItem("refresh");
            })
            .catch(error => console.log(error));
        }
        if (
          auth_token &&
          auth_token.id_token &&
          auth_token.refresh_token &&
          !(localStorage.getItem("code_auth") && localStorage.getItem("PCKE"))
        ) {
          refresh_oauth_tokens(auth_token)
            .then(_ => {
              console.log("refresh is ok!");
              //go call
            })
            .catch(error => console.log(error));
        }
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <div id="app">
      <div class="loader">
        <style>{`
            .loader {
                overflow: hidden;
                width: 100%;
                background: white;
                height: 100%;
                z-index: 10000;
                position: fixed;
                left: 0px;
            }
            
            .loader .loader-ringover {
                opacity: 1;
            }
            
            .loader-ringover {
                visibility: visible;
                opacity: 1;
                transition: .2s;
                content: "";
                position: absolute;
                z-index: 20;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: "#fff";
            }
            
            .loader-ringover:before {
                -webkit-animation: rotating 2s linear infinite;
                -moz-animation: rotating 2s linear infinite;
                -ms-animation: rotating 2s linear infinite;
                -o-animation: rotating 2s linear infinite;
                animation: rotating 2s linear infinite;
                content: "";
                position: absolute;
                z-index: 1;
                width: 195px;
                height: 195px;
                top: 50%;
                left: 50%;
                background: url('/loaderbg.png') no-repeat center / contain;
                border-radius: 50%;
            }
            
            .loader-ringover:after {
                content: "";
                position: absolute;
                z-index: 2;
                width: 200px;
                height: 200px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: url('/logo-loader.svg') no-repeat center / 200px;
            }
            
            /* login */
            
            @-webkit-keyframes rotating {
                from {
                    -webkit-transform: translate(-50%, -50%) rotate(0deg);
                    -o-transform: translate(-50%, -50%) rotate(0deg);
                    transform: translate(-50%, -50%) rotate(0deg);
                }
                to {
                    -webkit-transform: translate(-50%, -50%) rotate(360deg);
                    -o-transform: translate(-50%, -50%) rotate(360deg);
                    transform: translate(-50%, -50%) rotate(360deg);
                }
            }
            
            @keyframes rotating {
                from {
                    -ms-transform: translate(-50%, -50%) rotate(0deg);
                    -moz-transform: translate(-50%, -50%) rotate(0deg);
                    -webkit-transform: translate(-50%, -50%) rotate(0deg);
                    -o-transform: translate(-50%, -50%) rotate(0deg);
                    transform: translate(-50%, -50%) rotate(0deg);
                }
                to {
                    -ms-transform: translate(-50%, -50%) rotate(360deg);
                    -moz-transform: translate(-50%, -50%) rotate(360deg);
                    -webkit-transform: translate(-50%, -50%) rotate(360deg);
                    -o-transform: translate(-50%, -50%) rotate(360deg);
                    transform: translate(-50%, -50%) rotate(360deg);
                }
            }
        `}</style>
        <div class="loader-ringover"></div>
      </div>
    </div>
  );
};

export default LoginScreen;

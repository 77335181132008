import React from "react";
import "./Button.css";

const Button = ({ variant, label, onClick, type, form, key, ...rest }) => {
  var clsName = "";

  switch (variant) {
    case "outline":
      clsName = "btn-outline";
      break;

    case "fill-light":
      clsName = "btn-fill lt";
      break;

    case "primary":
      clsName = "btn-fill-primary";
      break;

    default:
      clsName = "btn-fill";
      break;
  }

  return (
    <button
      key={key}
      className={`custom-btn ${clsName}`}
      type={type}
      form={form}
      onClick={onClick}
      {...rest}
    >
      {label}
    </button>
  );
};

export default Button;

import React from "react";
import { createContext } from "react";
import RingoverSDK from "./ringover-sdk/ringover-sdk";

export const CallIframeContext = createContext();
const zoom = window.devicePixelRatio;

export const CallIframeProvider = ({ children }) => {
  const options = {
    container: null,
    position: {
      right: null,
    },
    animation: false,
    trayicon: false,
  };

  const simpleSDK = new RingoverSDK(options);

  return (
    <CallIframeContext.Provider value={simpleSDK}>
      {children}
    </CallIframeContext.Provider>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Signout.scss";
import { AuthContext } from "../../Context/AuthContext";
import logo from "../../Assets/Images/logo2.svg";
import Button from "../../components/Button/Button";
const logout_url = 'https://auth.ringover.com/oauth2/logout';

const Signout = () => {
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [signingOut, setSigningOut] = useState(0); // 0->ask to signout, 1->signing out, 2->signout success, 3->signout failed, 4->already signed out

  const handleSignOut = async () => {
    if (localStorage.getItem("refresh_token")) {
      setSigningOut(1);
      try {
        const token = window.localStorage.getItem('id_token');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('code_from_auth');
        window.localStorage.removeItem('region');
          const params = {
            id_token_hint: token,
            post_logout_redirect_uri: window.location.origin + '/login',
          };

          let url = logout_url + '?';
          for (let param in params) {
            url += param + '=' + params[param] + '&';
          }

          window.localStorage.setItem('logout', 'yes');
          window.location.href = encodeURI(url);
      } catch (error) {
        console.log(error);
        setSigningOut(3);
      }
    } 
  };

  useEffect(() => {
    if (!localStorage.getItem("refresh_token")) {
      setSigningOut(4);
    }
  }, []);

  return (
    <div className="signout-container">
      <div className="left">
        <img src={logo} alt="Logo" />
        {/* <h3>Teams Extension</h3> */}
      </div>
      <div className="right">
        <h2>Disconnect</h2>
        <div className="text-description">
          <span>
            {signingOut !== 4 ? signingOut === 2 ? "Disconnected" : `Are you sure you wish to disconnect from the extension?` : "You are already disconnected !"}
          </span>
        </div>
        {signingOut !== 4 && signingOut !== 2 && (
          <div className="buttons">
            <Button
              onClick={() => {
                navigate("/contact");
              }}
              type={"submit"}
              label={"No, cancel"}
              variant={"fill-light"}
            />
            <Button
              onClick={() => {
                if (signingOut === 0) {
                  handleSignOut();
                }
              }}
              type={"submit"}
              label={`${signingOut === 0 ? "Yes, Disconnect" : ""}${signingOut === 1 ? "Disconnecting" : ""
                }`}
              variant={"primary"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Signout;

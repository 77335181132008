import React from "react";
import "./CallViaFrame.scss";
import { CallIframeContext } from "../../Context/CallIframeContext";
import { ReactComponent as Call } from "../../Assets/Icons/Call.svg";
import { ReactComponent as Ringover } from "../../Assets/Icons/Ringover.svg";
import { useContext } from "react";

const CallViaFrame = ({ to, styles, className, rest, RingoverIcon }) => {
  const simpleSDK = useContext(CallIframeContext);

  const handleCall = (to) => {
    if (to && to !== "") {
      simpleSDK.show();
      simpleSDK.dial(to);
    }
  };

  return (
    <>
      {RingoverIcon ? (
        <Ringover
          style={{ ...styles }}
          className={`call-via-frame ${className}`}
          {...rest}
          onClick={() => handleCall(to)}
        />
      ) : (
        <Call
          style={{ ...styles }}
          className={`call-via-frame ${className}`}
          {...rest}
          onClick={() => handleCall(to)}
        />
      )}
    </>
  );
};

export default CallViaFrame;

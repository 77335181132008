/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./ContactInfoModal.scss";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as Send } from "../../Assets/Icons/Send.svg";
import { ReactComponent as Ringover } from "../../Assets/Icons/Ringover.svg";
import CallViaFrame from "../CallViaFrame/CallViaFrame";
import { sendSms } from "../../utils/Api";
import { truncate } from "../../utils/Functions";
function format_full_name(text) {
  let full_name = text.split(" ");
  let initials = full_name[0][0];
  if (text) {
    if (full_name.length >= 2 && full_name[1]) {
      initials += full_name[1][0];
    }
  } else {
    initials = '';
  }

  return initials.toString().toUpperCase();
}

const ContactInfoModal = ({ contact, numbers, setShowContactModal }) => {
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState({
    label: numbers[0]?.format?.international ?? numbers[0].number,
    value: numbers[0].number,
  });
  const [from, setFrom] = useState(
    JSON.parse(localStorage.getItem("smsPhone"))
  );
  const [fromOptions, setFromOptions] = useState([]);

  useEffect(async () => {
    const userData = await JSON.parse(localStorage.getItem("userData"));
    const numbers = userData.numbers ?? [];
    numbers.forEach((number) => {
      if (number?.canSendSMS) {
        const option = {
          label: number?.format?.international ?? number?.number,
          value: number?.number,
        };
        setFromOptions((prevstate) => [...prevstate, option]);
      }
    });
    return () => {
      setFromOptions([]);
    };
  }, [setFromOptions]);

  const handleMsgSend = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (msg.trim() !== "") {
      try {
        setLoading(true);
        await sendSms(to.value, msg.trim(), from);
        setMsg("");
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };
  // console.log(contact);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowContactModal(false);
      }}
    >
      <div className="cim-modal" id="sms-modal">
        <div className="cim-header">
          {" "}
          <div className="cim-user-profile-image-box">
            {contact?.picture ? (
              <img
                className="cim-user-profile-image"
                src={contact?.picture}
                alt="dp"
              />
            ) : (
              <span className="box-initial">
                {contact && contact.initial && contact.initial !== "" ? contact.initial
                  : contact && contact.lastname && !contact.initial ? format_full_name(contact.lastname)
                    : "NC"}
              </span>
            )}
            {contact?.company?.toLowerCase()?.includes("ringover") && (
              <span
                className={`ringover-icon-dp ${
                  contact?.picture ? "bg-transparent" : ""
                }`}
              >
                <Ringover />
              </span>
            )}
          </div>
          <div className="cim-name">{truncate(contact?.concat_name, 20)}</div>
        </div>
        {contact?.company && (
          <div className="cim-company">{contact?.company}</div>
        )}

        {contact?.isUser ? (
          <div className="msg-warning">
            You cannot send a direct message to a Ringover user
          </div>
        ) : (
          <>
            <div className="cim-phone">to: {to.label}</div>
            <div className="cim-input">
              <form
                onSubmit={(e) => {
                  handleMsgSend(e);
                }}
              >
                <textarea
                  disabled={loading}
                  autoFocus={true}
                  className="cim-input-msg"
                  placeholder="quick message"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                />
              </form>
              <div className="send-options">
                <span>
                  from:{" "}
                  <select
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                  >
                    {fromOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </span>
                <Send
                  onClick={() => {
                    !loading && handleMsgSend();
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="cim-cd-container">
          <div className="cim-cd-heading">Contact info</div>
          {numbers.map((number, index) => {
            const int_number = number?.format?.international;
            return (
              <div key={index} className={`cim-cd `}>
                <span
                  className={contact?.isUser ? "custom-disabled" : ""}
                  onClick={() =>
                    setTo({
                      label: int_number ?? number?.number,
                      value: number?.number,
                    })
                  }
                >
                  {int_number ?? number?.number}
                </span>
                <CallViaFrame to={int_number ?? number?.number} />
              </div>
            );
          })}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default ContactInfoModal;

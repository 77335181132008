import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
// import NavDropdown from 'react-bootstrap/NavDropdown'
import { NavLink } from "react-router-dom";
// import Logo from '../../Assets/Icons/Logo.svg'
import { ReactComponent as Logo } from "../../Assets/Icons/Logo.svg";
import "./NavBar.css";
const navigLang = navigator.language || navigator.userLanguage ? navigator.language || navigator.userLanguage : "fr";
function NavBar() {
  return (
    <div className="nav-bgrnd">
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            <Logo style={{ height: "35px" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active nav-list" : "inactive nav-list"
                }
                to="/callhistory"
              >
                {navigLang.startsWith('fr') ? "Journal des appels" : "Call History"}
                
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  isActive ? "active nav-list" : "inactive nav-list"
                }
                to="/contact"
              >
               Contacts
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active nav-list" : "inactive nav-list"
                }
                to="/signout"
              >
               {navigLang.startsWith('fr') ? "Déconnexion" : "Log Out"}
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="nav-bottom-line"></div>
    </div>
  );
}

export default NavBar;

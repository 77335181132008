import axios from "axios";
import config from "./config";

export async function sendSms(to, message, from) {
  let To = String(to);
  let From = String(from ?? JSON.parse(localStorage.getItem("smsPhone")));

  if (To.charAt(0) !== "+") {
    To = "+" + To;
  }
  if (From.charAt(0) !== "+") {
    From = "+" + From;
  }

  var postData = {
    archivedAuto: false,
    fromNumber: From,
    toNumber: To,
    message: message,
    newChat: true,
    userId: localStorage.getItem("userId"),
  };
  // console.log(postData);
  let axiosConfig = {
    headers: {
      apikey: config.apikey,
    },
  };
  // console.log(postData, axiosConfig);
  try {
    var url = config.rootUrl + `/contacts/sendSMS`;
    var response = await axios.post(url, postData, axiosConfig);
    // console.log("response sms : ", response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function dial(number) {
  const from = JSON.parse(localStorage.getItem("primaryPhone"));
  var url = config.rootUrl + `contacts/callToUser?from=${from}&to=${number}`;
  // console.log(url);
  try {
    await axios.get(url, {
      headers: {
        apikey: config.apikey,
      },
    });
  } catch (err) {
    console.log(err.message);
    alert("failed: ", err.message);
  }
}

export function getCallHistoryByContact(phonenumber) {
  const uid = localStorage.getItem("userId");
  var url =
    config.rootUrl +
    `contacts/getCallHistoryByContact?userId=${uid}&contactNumber=${phonenumber}&pageLimit=10`;
  try {
    axios
      .get(url, {
        headers: {
          apikey: config.apikey,
        },
      })
      .then((response) => {
        return response;
      });
  } catch (error) {
    console.log(error);
  }
}

export const checkAvailability = async (user_id) => {
  const api_key = config.apikey;
  const url = config.rootUrl + `contacts/checkPresence?userId=${user_id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        apikey: api_key,
      },
    });
    return !response?.body?.is_snoozed;
  } catch (error) {
    console.log(error);
  }
};

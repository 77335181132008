import React from "react";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import { themeStyles } from "./ThemeStyles";
// import "./Select.scss";

const themes = {
  normal: "",
  transparent: "",
  small: "",
};

/**
 * This component is used to render a Select Option Component.
 *
 * @component
 * @example
 * const [value, setValue] = useState("option 1")
 *
 * return (
 * 	<Select
 *  	options={[
 *			{label: "Option 1", value: "option 1"},
 *			{label: "Option 2", value: "option 2"},
 *			{label: "Option 3", value: "option 3"}
 *		]}
 *		value={value}
 *		setValue={setValue}
 *	/>
 * )
 */

const Select = ({
  options,
  value,
  setValue,
  theme,
  width,
  height,
  placeholder,
  menuOnTop,
  disabled,
  isSearchable,
  ...rest
}) => {
  const [normalStyles, transparentStyles] = themeStyles(
    width,
    height,
    menuOnTop
  );

  themes.normal = normalStyles;
  themes.transparent = transparentStyles;

  const setSelected = (selected) => {
    setValue(selected.value);
  };

  let selectedOption;

  options?.forEach((option) => {
    if (option.value === value) {
      selectedOption = option;
    }
  });

  return (
    <ReactSelect
      options={options}
      value={selectedOption}
      onChange={setSelected}
      styles={themes.transparent}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={disabled}
      // classNamePrefix="crm-select"
      {...rest}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(Object.keys(themes)),
  width: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  menuOnTop: PropTypes.bool,
};

Select.defaultProps = {
  theme: "normal",
  width: "130px",
  height: "44px",
  menuOnTop: false,
  disabled: false,
  isSearchable: false,
};

export default Select;

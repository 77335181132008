/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import "./Contact.css";
import { truncate } from "../../utils/Functions";
// import { ReactComponent as Sms } from "../../Assets/Icons/Sms.svg";
import "../../Styles/style.css";
import CallViaFrame from "../CallViaFrame/CallViaFrame";
// import QuickSMSModal from "../QuickSMSModal/QuickSMSModal";
import ContactInfoModal from "../ContactInfoModal/ContactInfoModal";
import { ReactComponent as Ringover } from "../../Assets/Icons/Ringover.svg";
// import { object } from "prop-types";

export default function ContactTab({ obj, handleNavigation }) {
  // const [showSMSModal, setShowSMSModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const capitalize = (str) => {

    return (str && typeof str !== undefined && str.length > 0) ? `${str[0].toUpperCase()}${str.slice(1)}` : ''
    // return str[0].toUpperCase() + str.slice(1)   // without template string
  }

  //console.log(obj)
  return (
    <div className="outer-crd-contact">
      {/* {showContactModal && (
        <ContactInfoModal
          contact={obj}
          numbers={obj.numbers}
          setShowContactModal={setShowContactModal}
        />
      )} */}
      <div className="inner-crd-left">
        <div
          className="user-profile-image-box"
          // onClick={() => {
          //   setShowContactModal(true);
          // }}
        >
          {obj && obj.picture  ? (
            <img className="user-profile-image" src={obj.picture} />
          ) : (
            <span className="box-initial">
              {obj && obj.concat_name && obj.concat_name.length > 0 && typeof obj.concat_name !== 'undefined' ? (
                obj.concat_name.charAt(0)
              ):(
                "?"
              )}
            </span>
          )}
          {obj?.company?.toLowerCase()?.includes("ringover") && (
            <span
              className={`ringover-icon-dp ${
                obj.picture ? "bg-transparent" : ""
              }`}
            >
              <Ringover />
            </span>
          )}
        </div>
        <h5
          className="concat-name padding-left cursor-pointer"
          // onClick={() => {
          //   handleNavigation(obj.contact_id, obj);
          // }}
        >
          {" "}
          {obj && obj.concat_name && obj.concat_name.length > 0 && typeof obj.concat_name !== 'undefined' ? (
              truncate(capitalize(obj.concat_name), 15)
              ):(
                ""
              )}
        </h5>
      </div>

      <div
        className="inner-crd"
        // onClick={() => handleNavigation(obj.contact_id, obj)}
      >
        {obj?.numbers && obj.numbers.length !== 0
          ? obj.numbers.map((num, ind) => {
              if (ind >= 3) {
                return null;
              }
              return (
                <div className="inner-crd-number" key={ind}>
                  <div className="inner-crd">
                    {num && (
                      <p
                        className={`${
                          ind === 0 ? "num-number" : "num-number-secondary"
                        }`}
                      >
                        {" "}
                        {num.format.international}
                      </p>
                    )}
                  </div>
                </div>
              );
            })
          : 'unknown'}
      </div>
      <div className="contact-crd-call">
        <CallViaFrame to={obj?.numbers[0]?.format?.international} />
      </div>
      {/* <div className="contact-crd-sms">
        <Sms
          className="sms-icon-callhistory"
          onClick={() => setShowSMSModal(!showSMSModal)}
        />
        {showSMSModal && (
          <QuickSMSModal
            to={obj?.numbers[0]?.format?.international}
            concat_name={obj.concat_name}
            setShowSMSModal={setShowSMSModal}
          />
        )}
      </div> */}
    </div>
  );
}

import React from "react";
import "./NotesModal.scss";

const NotesModal = ({ text }) => {
  return (
    <div className="ch-notes">
      <div className="notes-heading">Note </div>
      {text}
    </div>
  );
};

export default NotesModal;

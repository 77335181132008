import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import "./PrivateRoute.scss";
import RingoverCall from "../RingoverCall/RingoverCall";
import NavBar from "../NavBar/NavBar";
// import Dialer from "../Dialer/Dialer";

export default function PrivateRoute({ component: RouteComponent, ...rest }) {
  const { loading, authenticated } = useContext(AuthContext);
  const pathname = window.location.pathname;
  if (loading) {
    return null;
  } else {
    return authenticated ? (
      <div className="page-container">
        <NavBar />
        <div className="fullscreen-page">
          <div className="dial-section">
            <RingoverCall />
          </div>
          <div className="content-section">
            <Outlet />
          </div>
        </div>
      </div>
    ) : (
      <Navigate to="/login" state={{ pathname: pathname }} />
    );
  }
}

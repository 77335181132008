/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./CallHistoryContacts.css";
import { callIcon } from "../../utils/Functions";
import { callType } from "../../utils/Functions";
import sec2time from "../../utils/Functions";
import moment from "moment";

export const CallHistoryContacts = ({
  direction,
  startTime,
  totalDuration,
}) => {
  var time = moment(startTime).format("HH:mm, DD/MM/YYYY");
  return (
    <div className="outer-box-callhistory-contacts">
      <div>
        <img
          className="outer-box-callhistory-contacts-icon"
          src={callIcon(direction, totalDuration)}
        />
      </div>
      <div className="box-callhistory-contacts-callType">
        {callType(direction, totalDuration)}
      </div>
      <div className="box-callhistory-contacts-callduration">
        {sec2time(totalDuration)}
      </div>
      <div className="box-callhistory-contacts-time">{time}</div>
    </div>
  );
};

export const Colors = {
	//colors
	primary: "linear-gradient(231.61deg, #42d8d6 20%, #3fdfcf 71.51%)",
	secondary: "#2d5380",
	blueShade1: "#027dfc",
	blueShade2: "#3d5681",
	red: "#ef525e",
	redShade2: "#ed4e75",
	orange: "#ff754a",
	orangeGradient:
		"linear-gradient(63.17deg, #ff754a -16.06%, #ffb12a 128.22%)",
	yellow: "#ffb12a",
	green: "#00ddd0",
	magenta: "#de0d92",

	//b&w shades
	black: "#1a1a1a",
	blackShade1: "#404040",
	blackShade2: "#595959",
	blackShade3: "#939393",
	blackShade4: "#bfbfbf",
	blackShade5: "#ececec",

	white: "#ffffff",
	whiteShade1: "#fafafa",
	whiteShade2: "#f1f2f4",
};

export default Colors;
